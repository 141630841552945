import { NextPage } from "next";
import Image from "next/image";
import Link from "next/link";
import AutomatrixFooter from "components/AutomatrixFooter";
import { useRouter } from "next/router";

const Custom404: NextPage = () => {
    const router = useRouter();
    return (
        <>
            <div style={{ backgroundColor: '#464242', height: 70 }} className="topbar">
            </div>
            <section className="error-404 error-page white-bg page-section-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div id="primary" className="site-content">
                                <div id="content" role="main">
                                    <article className="post error404 no-results not-found">
                                        <header className="entry-header">
                                        </header>
                                        <div className="entry-content">
                                            <div className="error-content text-center">
                                                <Image width={460} height={142} style={{ marginTop: 100, marginBottom: 50 }} src="/static/images/img404.jpg" alt="404" title="404" />
                                                <br />
                                                <h3 className="p-t-20 text-red">Ooopps...</h3>
                                                <strong className="text-black">The Page you were looking for, could not be found.</strong>
                                                <div className="error-search">
                                                    <div className="row gray-form no-gutter">
                                                        <div className="p-b-10 col-md-6 col-xs-12">
                                                            <Link href="/" className="button red">Go to homepage</Link>
                                                        </div>
                                                        <div className="col-md-6 col-xs-12">
                                                            <Link href="/vehicles" className="button red">Find my next car</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {['/404'].includes(router.pathname) &&
                <div style={{ position: 'absolute', width: '100%', bottom: 0 }}>
                    <AutomatrixFooter />
                </div>
            }
        </>
    );
};

export default Custom404;