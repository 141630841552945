import { cloudinaryLoader } from "utils/cloudinaryUtils";
import dynamic from "next/dynamic";

const Link = dynamic(() => import("next/link"));
const Image = dynamic(() => import("next/image"));

function AutomatrixFooter({ footerSettings, companySettings }: any) {
    const companyCode = companySettings?.companyCode ?? 0;
    const companyName = companySettings?.companyCode ?? "Dealer name";
    const showPrivacyPolicyLink = footerSettings?.showPrivacyPolicyLink ?? true;
    const showSiteMapLink = footerSettings?.showSiteMapLink ?? true;
    const showAccesibility = footerSettings?.showAccesibility ?? true;

    return (
        <footer className="footer-2 footer-copyright">
            <div className="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="text-left">
                                {companyCode === 185 && (
                                    <p>
                                        <a
                                            rel="noreferrer"
                                            href="https://www.automatrix.com?utm_source=dealerwebsites&utm_medium=automatrixwebsites&utm_campaign=dealer_websites"
                                            target="_blank"
                                        >
                                            <Image
                                                loader={cloudinaryLoader}
                                                src="https://automatrix-cms.mo.cloudinary.net/content/1/6/images/automatrix-logo.png"
                                                className="p-l-10"
                                                fill
                                                width={173}
                                                height={22}
                                                alt="Automatrix DMS Software"
                                            />
                                        </a>
                                        <span>
                      1983 - {new Date().getUTCFullYear()} &copy; {companyName}
                      All rights reserved
                                        </span>
                                    </p>
                                )}

                                {companyCode !== 185 && (
                                    <p>
                    &copy;Copyright {new Date().getUTCFullYear()} Developed by{" "}
                                        <a
                                            rel="noreferrer noopener"
                                            href="https://www.automatrix.com?utm_source=dealerwebsites&utm_medium=automatrixwebsites&utm_campaign=dealer_websites"
                                            target="_blank"
                                        >
                                            <Image
                                                loader={cloudinaryLoader}
                                                src="https://automatrix-cms.mo.cloudinary.net/content/1/6/images/automatrix-logo.png"
                                                className="p-l-10"
                                                // layout="fixed"
                                                width={173}
                                                height={22}
                                                alt="Automatrix DMS Software"
                                            />
                                        </a>
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <ul className="list-inline text-right">
                                {showPrivacyPolicyLink && (
                                    <li>
                                        <Link href="privacypolicy" prefetch={false}>
                                            Privacy Policy |
                                        </Link>
                                    </li>
                                )}
                                {companyCode === 160 && (
                                    <li>
                                        <a
                                            target="_blank"
                                            href="https://www.orders-online.biz/~cdpaxiom/cgi-bin/qnet-login.cgi?Client=1368&CC=1&User=Master&Passwd=PublicAuto" rel="noreferrer"
                                        >
                      Current Employees{" "}
                                        </a>{" "}
                    |{" "}
                                    </li>
                                )}
                                {showSiteMapLink && (
                                    <li>
                                        <Link href="/sitemap" prefetch={false}>
                                            Sitemap |
                                        </Link>
                                    </li>
                                )}
                                {showAccesibility && (
                                    <li>
                                        <Link href="/accessibility" prefetch={false}>
                                            Accessibility
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default AutomatrixFooter;
